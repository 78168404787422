import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, Subscription, take} from 'rxjs';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { CodelistsService } from '../../../service/codelists.service';
import { Offer, OfferArticle } from '../../../models/offer.model';
import { OffersService } from '../../../service/offers.service';
import { Customer } from '../../../models/customer.model';
import { Article } from '../../../models/article.model';
import {CustomersFilter, CustomersService} from '../../../service/customers.service';
import { ArticlesFilter, ArticlesService } from '../../../service/articles.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '../../../service/projects.service';
import { PricesService } from '../../../service/prices.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-offer-full-modal',
  templateUrl: './offer-full-modal.component.html',
  styleUrls: ['./offer-full-modal.component.scss']
})
export class OfferFullModalComponent implements OnInit, OnDestroy {

  loading = true;
  filter: ArticlesFilter = new ArticlesFilter(0, 5, 'name');
  customersFilter: CustomersFilter = new CustomersFilter(0, 5, 'name');
  form: UntypedFormGroup;
  buyersForm: UntypedFormGroup;
  receiverForm: UntypedFormGroup;
  sub: Subscription;
  filtered: any;
  stepsItems: MenuItem[];
  activeIndex = 0;
  offer: Offer;
  parities: any[];
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private items: BehaviorSubject<Article[]> = new BehaviorSubject([]);
  items$: Observable<Article[]> = this.items.asObservable();
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  count$: Observable<number> = this.count.asObservable();
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);
  pending$: Observable<boolean> = this.pending.asObservable();

  expandedRows: { [key: string]: boolean } = {};

  @Input() buyer: Customer;

  constructor(
    private offersService: OffersService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    private customersService: CustomersService,
    private articlesService: ArticlesService,
    private projectsService: ProjectsService,
    public pricesService: PricesService,
    private modal: DialogModule,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private translate: TranslateService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      number: fb.control(null),
      dateCreated: fb.control(new Date(), Validators.required),
      dateValid: fb.control(new Date(), Validators.required),
      dateDelivery: fb.control(new Date(), Validators.required),
      regNumber: fb.control(null),
      remarks: fb.control(null),
      seller: null,
      buyer: this.buyersForm,
      receiver: this.receiverForm,
      offersArticles: fb.array([]),
      discount: fb.control(0, Validators.required),
      discountArticles: fb.control(null),
      priceNet: fb.control(null),
      pricePerUnit: fb.control(null),
      priceGross: fb.control(null),
      priceTotal: fb.control(null),
      vatCost: fb.control(null),
      project: fb.control(null),
      parity: fb.control(null),
      daysToDelivery: fb.control(null, Validators.required),
    });
  }

  get offersArticlesFormArray() {
    return this.form.controls.offersArticles as UntypedFormArray;
  }

  ngOnInit(): void {
    this.stepsItems = [
      {
        label: this.translate.instant('CUSTOMER.CUSTOMER'),
        command: (event: any) => {
          this.activeIndex = 0;
        }
      },
      {
        label: this.translate.instant('MENU.ARTICLES'),
        command: (event: any) => {
          this.activeIndex = 1;
        }
      },
      {
        label: this.translate.instant('OFFER.COMMERCIAL_TERMS&CONDITIONS'),
        command: (event: any) => {
          this.activeIndex = 2;
        }
      }
    ];
    if (this.config && this.config.data) {
      if (this.config.data.offer) {
        this.offer = this.config.data.offer;
      } else {
        if (this.config.data.buyer) {
          this.fetchCustomer(this.config.data.buyer);
        }
        if (this.config.data.articles) {
          this.addSellerToOffer();
          this.config.data.articles.forEach(article => {
            this.addArticleOnOffer(article);
          });
        }
      }
    }
    if (this.offer && this.offer.id) {
      this.form.patchValue(this.offer);

      // Fill the articles
      if (this.offer.offersArticles.length !== 0) {
        this.offer.offersArticles.forEach(offerArticle => {
          this.addOfferArticleOnOffer(offerArticle);
        });
      }
      // Create new date from existing offer to get rid of error
      this.form.get('dateCreated').setValue(new Date(this.offer.dateCreated));
      this.form.get('dateValid').setValue(new Date(this.offer.dateValid));
      this.form.get('dateDelivery').setValue(new Date(this.offer.dateDelivery));
    } else {
      // Create new date from existing offer to get rid of error
      this.form.get('dateCreated').setValue(new Date());
      this.form.get('dateValid').setValue(new Date());
      this.form.get('dateDelivery').setValue(new Date());
      this.addSellerToOffer();
    }
  }

  ngOnDestroy(): void {
    this.saving.complete();
    this.pending.complete();
    this.items.complete();
    this.count.complete();
  }

  fetchArticles(): void {
    // If using lazy load and loading MUST use timeout... https://github.com/primefaces/primeng/issues/3042
    setTimeout(() => {
      this.loading = true;
      this.pending.next(true);
      this.sub = this.articlesService.findWithPrice(this.filter).subscribe(data => {
          this.pending.next(false);
          this.items.next(data.values);
          this.count.next(data.count);
          this.loading = false;
        },
        error => {
          console.log(error);
        });
    }, 0);
  }

  fetchCustomer(id: string): void {
    this.loading = true;
    this.customersService.get(id)
      .subscribe(
        data => {
          this.form.get('buyer').setValue(data);
        },
        error => {
          console.log(error);
        });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetchArticles();
  }

  onRowEditSave(offerArticle: OfferArticle, index: number): void {
    console.log('onRowEditSave');
    if (offerArticle.quantity > 0) {
      // Recalculate price based on PriceNet
      offerArticle.pricePerUnit = this.pricesService.calculateArticlePricePerUnit(offerArticle);
      offerArticle.article.priceTotal = this.pricesService.articlePriceTotal(offerArticle);
      offerArticle.priceTotal = this.pricesService.articlePriceTotal(offerArticle);
      this.offersArticlesFormArray.at(index).patchValue(offerArticle);
    }
  }

  changePricePerUnit(offerArticle: OfferArticle, index: number): void {
    if (offerArticle.quantity > 0) {
      // Recalculate price based on pricePerUnit
      offerArticle.priceNet = this.pricesService.calculateArticlePriceNet(offerArticle);
      offerArticle.article.priceTotal = this.pricesService.articlePriceTotal(offerArticle);
      offerArticle.priceTotal = this.pricesService.articlePriceTotal(offerArticle);
      this.offersArticlesFormArray.at(index).patchValue(offerArticle);
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const offer = {...this.offer, ...this.form.getRawValue()};
    // If no receiver is selected than we set the same as buyer
    if (!offer.receiver) {
      offer.receiver = offer.buyer;
    }
    offer.priceNet = this.pricesService.offerPriceWithoutDiscount(this.form.getRawValue());
    offer.discountArticles = this.pricesService.articlesPriceDiscount(this.form.getRawValue());
    offer.vatCost = this.pricesService.offerPriceVat(this.form.getRawValue());
    if (offer.id) {
      this.offersService.update(offer.id, offer)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated offer: ' + offer.number,
            });
            this.ref.close(offer);
            this.router.navigate(['offer/' + response.id]);
          },
          error => {
            console.log(error);
          });
    } else {
      this.offersService.create(offer)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created offer: ' + response.number,
            });
            this.ref.close(offer);
            this.router.navigate(['offer/' + response.id]);
          },
          error => {
            console.log(error);
          });
    }
  }

  filterCustomers(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.customersService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterProjects(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.projectsService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  /*  Articles on form  */

  addSellerToOffer(): void {

    const seller = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      registrationNumber: new UntypedFormControl(null),
      name: new UntypedFormControl('Chemcolor Sevnica d.o.o.'),
      nameLong: new UntypedFormControl('Chemcolor Sevnica d.o.o.'),
      url: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      mobile: new UntypedFormControl(null),
      fax: new UntypedFormControl(null),
      vat: new UntypedFormControl(null),
      company: new UntypedFormControl(true),
      contacts: new UntypedFormArray([]),
      address: new UntypedFormGroup({
        id: new UntypedFormControl(null),
        street1: new UntypedFormControl('Dolnje Brezovo 35'),
        street2: new UntypedFormControl(null),
        city: new UntypedFormControl('Blanca'),
        postal: new UntypedFormControl('Blanca'),
        postalCode: new UntypedFormControl('8283'),
        stateOrProvince: new UntypedFormControl(null),
        country: new UntypedFormGroup({
          id: new UntypedFormControl(15000196),
          name: new UntypedFormControl('Slovenia'),
          description: new UntypedFormControl(null),
          code2: new UntypedFormControl('SI'),
          code3: new UntypedFormControl('SVN'),
          vat1: new UntypedFormControl(22),
          vat2: new UntypedFormControl(9.5),
        }),
      }),
    });
    this.form.controls.seller.setValue(seller.getRawValue());
  }

  addOfferArticleOnOffer(offerArticle: OfferArticle): void {
    const offerArticleForm = new UntypedFormGroup({
      id: new UntypedFormControl(offerArticle.id),
      article: new UntypedFormControl(offerArticle.article),
      offer: new UntypedFormControl(null),
      priceNet: new UntypedFormControl(offerArticle.priceNet),
      pricePerUnit: new UntypedFormControl(this.pricesService.calculateArticlePricePerUnit(offerArticle)),
      priceGross: new UntypedFormControl(offerArticle.priceGross),
      priceList: new UntypedFormControl(offerArticle.priceList),
      discount: new UntypedFormControl(offerArticle.discount),
      discountList: new UntypedFormControl(offerArticle.discountList),
      priceTotal: new UntypedFormControl(offerArticle.priceTotal),
      quantity: new UntypedFormControl(offerArticle.quantity),
      vat1: new UntypedFormControl(this.form.getRawValue().seller.address.country.vat1),
      vat2: new UntypedFormControl(this.form.getRawValue().seller.address.country.vat2),
    });

    this.offersArticlesFormArray.push(offerArticleForm);
  }

  addArticleOnOffer(article: Article): void {
    let articleExists = false;
    this.offersArticlesFormArray.controls.forEach(value => {
      if (value.get('article').value.id === article.id) {
        articleExists = true;
      }
    });

    if (!articleExists) {
      article.quantity = 1;
      article.discount = 0;
      const offerArticleForm = new UntypedFormGroup({
        id: new UntypedFormControl(null),
        article: new UntypedFormControl(article),
        priceNet: new UntypedFormControl(article.priceList.price),
        priceGross: new UntypedFormControl(article.priceGross),
        priceList: new UntypedFormControl(article.priceList),
        discount: new UntypedFormControl(article.discount),
        discountList: new UntypedFormControl(article.discountList),
        priceTotal: new UntypedFormControl(article.priceTotal),
        quantity: new UntypedFormControl(article.quantity),
        vat1: new UntypedFormControl(this.form.getRawValue().seller.address.country.vat1),
        vat2: new UntypedFormControl(this.form.getRawValue().seller.address.country.vat2),
      });

      this.offersArticlesFormArray.push(offerArticleForm);
    } else {
      this.messageService.add({severity: 'info', summary: this.translate.instant('OFFER.ARTICLE_ALREADY_ADDED')});
    }
  }

  updatePricesOnOfferArticles(): void {
    const articleIds = this.offersArticlesFormArray.controls
      .map(control => (control.get('article').value as Article).id);

    if (articleIds.length === 0) { return; }

    const requests$ = articleIds.map(id =>
      this.articlesService.findWithPriceById(id, this.filter.customerId).pipe(
        tap(updatedArticle => {
          const control = this.offersArticlesFormArray.controls.find(
            c => (c.get('article').value as Article).id === updatedArticle.id
          );
          if (control) {
            // Update only the price instead of the entire article
            control.get('priceNet').setValue(updatedArticle.priceList.price);

            // Get current quantity and discount
            const quantity = control.get('quantity').value;
            const discount = control.get('discount').value;

            // Calculate total price if quantity exists
            if (quantity !== null) {
              let totalPrice = updatedArticle.priceList.price * quantity;

              // Apply discount if it exists
              if (discount !== null) {
                totalPrice = totalPrice * (1 - discount / 100); // assuming discount is in percentage
              }

              // Update the total price
              control.get('priceTotal').setValue(totalPrice);

            }
          }
        })
      )
    );

    forkJoin(requests$).pipe(
      take(1)
    ).subscribe({
      next: (updatedArticles) => {
        this.messageService.add({
          severity: 'success',
          summary: this.translate.instant('ACTIONS.SUCCESS'),
          detail: this.translate.instant('ARTICLES.PRICES_UPDATED', { count: updatedArticles.length })
        });
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: this.translate.instant('ACTIONS.ERROR'),
          detail: this.translate.instant('ARTICLES.PRICES_UPDATE_ERROR')
        });
        console.error('Error updating articles:', error);
      }
    });
  }


  onRowDelete(offerArticle: OfferArticle, index: number): void {
    this.offersArticlesFormArray.removeAt(index);
  }

  selectBuyer(event: any): void {
    this.filter.customerId = event.value.id;
    this.fetchArticles();
    this.updatePricesOnOfferArticles();
  }

  onToggleRow(index: number) {
    if (this.expandedRows[index]) {
      delete this.expandedRows[index];
    } else {
      this.expandedRows[index] = true;
    }
    console.log('Toggled row:', index, this.expandedRows);

  }

}
