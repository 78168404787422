import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuthService} from '../service/auth.service';
import {DeliveryNote} from '../models/deliveryNote.model';
import {DeliveryNotesFilter, DeliveryNotesService} from '../service/deliveryNotes.service';
import {
  DeliveryNoteFullModalComponent
} from '../core/modals/delivery-note-full-modal/delivery-note-full-modal.component';

@Component({
    selector: 'app-delivery-notes',
    templateUrl: './delivery-notes.component.html',
    styleUrls: ['./delivery-notes.component.scss']
  })
  export class DeliveryNotesComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<DeliveryNote[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<DeliveryNote[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  filter: DeliveryNotesFilter = new DeliveryNotesFilter(0, 10, '-dateCreated');

  deliveryNotes: DeliveryNote[];
  selectedDeliveryNote: DeliveryNote;

  ref: DynamicDialogRef;
  loading = true;

  constructor(
    private deliveryNotesService: DeliveryNotesService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private translate: TranslateService,
    public auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    // Check if you come from home -> create = 1 then open modal for create new deliveryNote
    if (this.route.snapshot.queryParamMap.get('buyer')) {
      this.openModal(this.route.snapshot.queryParamMap.get('buyer'), null);
    } else if (this.route.snapshot.queryParamMap.get('create') === '1') {
      this.openModal(null, null);
    }
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.deliveryNotesService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.deliveryNotes = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  viewCustomer(id): void {
    this.router.navigate(['customer/' + id]);
  }

  viewDeliveryNote(id): void {
    this.router.navigate(['deliveryNote/' + id]);
  }

  openModal(buyer: string, input: DeliveryNote): void {
    this.ref = this.dialogService.open(DeliveryNoteFullModalComponent, {
      header: this.translate.instant('DELIVERY_NOTE.DELIVERY_NOTE'),
      maximizable: true,
      styleClass: 'grey-background-modal',
      width: '98vw',
      height: '96vh',
      data: {
        buyer,
        deliveryNote: input
      }
    });

    this.ref.onClose.subscribe((deliveryNote: DeliveryNote) => {
      if (deliveryNote) {
        this.fetch();
      }
    });
  }

  delete(deliveryNote: DeliveryNote): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete deliveryNote ' + deliveryNote.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deliveryNotesService.delete(deliveryNote.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted deliveryNote: ' + deliveryNote.number,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
