import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import {PriceListsService} from '../../../service/pricelists.service';

@Component({
  selector: 'app-upload-file-full-modal',
  templateUrl: './upload-file-full-modal.component.html',
  styleUrls: ['./upload-file-full-modal.component.scss'],
})
export class UploadFileFullModalComponent implements OnInit {
  @Input() id: number | null = null; // Reference for the related priceList
  @Input() header = 'Upload File'; // Modal dialog header

  @Output() onUploaded: EventEmitter<any> = new EventEmitter(); // Notify parent component when upload is successful

constructor(  private ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
              private messageService: MessageService,
              private priceListsService: PriceListsService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.id = this.config.data.id;
    }
  }

  onFileUpload(event: any): void {
    const file = event.files[0]; // Retrieve the uploaded file
    if (!file || !this.id) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please make sure a valid file and id are selected.',
      });
      return;
    }

    // Call the service to upload the file
    this.priceListsService.upload(this.id, file).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'File uploaded successfully!',
        });
        this.onUploaded.emit(response); // Notify the parent component
        this.closeModal(); // Close the modal
      },
      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Upload Failed',
          detail: 'An error occurred while uploading the file.',
        });
      },
    });
  }

  closeModal(): void {
    if (this.ref) {
      this.ref.close(); // Close the dialog using the ref
    }
  }
}
