<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>MENU.PRICE_LISTS</h5>
      <p-table #dt [value]="pricelists" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="['attachment.name', 'attachment.size']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
              <p-button icon="pi pi-plus" class="p-button-raised p-w-100" (click)="openModal(null)"
                        [label]="'PRICELISTS.CREATE_PRICELIST' | translate"></p-button>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="id">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.ID
                <p-sortIcon field="id"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="title">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.TITLE
                <p-sortIcon field="title"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="description">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.DESCRIPTION
                <p-sortIcon field="description"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="version">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.VERSION
                <p-sortIcon field="version"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="customerId">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.CUSTOMER_ID
                <p-sortIcon field="customerId"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="customerTypeId">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.CUSTOMER_TYPE_ID
                <p-sortIcon field="customerTypeId"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="startDate">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.START_DATE
                <p-sortIcon field="startDate"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="endDate">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                PRICELISTS.END_DATE
                <p-sortIcon field="endDate"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pricelist>
          <tr class="p-row">
            <td>
              {{pricelist.id}}
            </td>
            <td>
              {{pricelist.title}}
            </td>
            <td>
              {{pricelist.description}}
            </td>
            <td>
              {{pricelist.version}}
            </td>
            <td>
              {{pricelist.customerId}}
            </td>
            <td>
              {{pricelist.customerTypeId}}
            </td>
            <td>
              {{pricelist.startDate | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              {{pricelist.endDate | date: 'dd.MM.yyyy'}}
            </td>
                        <td>
                          <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                                  (click)="openModal(pricelist)"></button>
<!--                          <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"-->
<!--                                  (click)="deleteAttachment(pricelist)"></button>-->
                        </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
