<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>MENU.PRICELIST.DATA</h5>
      <p-table #dt [value]="priceListData" dataKey="id"
               [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]" [loading]="loading" [paginator]="true"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0" [globalFilterFields]="['article.nameLong', 'price']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span></span>
            <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                           placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
              </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="nameLong" style="width: 35%">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLES.NAME
                <p-sortIcon field="nameLong"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="price">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                ARTICLE.PRICE_RETAIL
                <p-sortIcon field="price"></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-priceListData>
          <tr class="p-row">
            <td>
              {{priceListData.article.nameLong}}
            </td>
            <td>
              {{priceListData.price}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>FILES.NO_FILES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
