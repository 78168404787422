import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Offer } from '../models/offer.model';
import {OffersFilter, OffersService} from '../service/offers.service';
import {ConfirmationService, LazyLoadEvent, MessageService} from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OfferFullModalComponent } from '../core/modals/offer-full-modal/offer-full-modal.component';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {KeycloakService} from 'keycloak-angular';
import {AuthService} from '../service/auth.service';

@Component({
    selector: 'app-offers',
    templateUrl: './offers.component.html',
    styleUrls: ['./offers.component.scss']
  })
  export class OffersComponent implements OnInit, OnDestroy {

  private items: BehaviorSubject<Offer[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<Offer[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));

  filter: OffersFilter = new OffersFilter(0, 10, '-dateCreated');

  offers: Offer[];
  selectedOffer: Offer;

  ref: DynamicDialogRef;
  loading = true;

  constructor(
    private offersService: OffersService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private translate: TranslateService,
    public auth: AuthService,
  ) {
  }

  ngOnInit(): void {
    // Check if you come from home -> create = 1 then open modal for create new offer
    if (this.route.snapshot.queryParamMap.get('buyer')) {
      this.openModal(this.route.snapshot.queryParamMap.get('buyer'), null);
    } else if (this.route.snapshot.queryParamMap.get('create') === '1') {
      this.openModal(null, null);
    }
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  fetch(): void {
    this.loading = true;
    this.pending.next(true);
    this.offersService.find(this.filter).subscribe(data => {
        this.pending.next(false);
        this.items.next(data.values);
        this.count.next(data.count);
        this.offers = data.values;
        this.loading = false;
      },
      error => {
        console.log(error);
      });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }

  viewCustomer(id): void {
    this.router.navigate(['customer/' + id]);
  }

  viewOffer(id): void {
    this.router.navigate(['offer/' + id]);
  }

  openModal(buyer: string, input: Offer): void {
    this.ref = this.dialogService.open(OfferFullModalComponent, {
      header: this.translate.instant('OFFER.OFFER'),
      width: '98vw',
      height: '96vh',
      maximizable: true,
      styleClass: 'grey-background-modal',
      data: {
        buyer,
        offer: input
      }
    });

    this.ref.onClose.subscribe((offer: Offer) => {
      if (offer) {
        this.fetch();
      }
    });
  }

  delete(offer: Offer): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete offer ' + offer.number + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.offersService.delete(offer.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted offer: ' + offer.number,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }
}
