<!--   Content    -->
    <form [formGroup]="form">
      <!--  Customer Information  -->
      <div class="grid">
        <div class="col-12 md:col-9">
          <div class="card p-fluid">
            <div class="grid">
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="dateCreated" class="required-mark" translate>OFFER.DATE_CREATED</label>
                <p-calendar id="dateCreated"
                            formControlName="dateCreated"
                            dateFormat="dd.mm.yy"></p-calendar>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="dateValid" class="required-mark" translate>OFFER.DATE_VALID</label>
                <p-calendar id="dateValid"
                            formControlName="dateValid"
                            dateFormat="dd.mm.yy"></p-calendar>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="daysToDelivery" class="required-mark" translate>OFFER.DAYS_TO_DELIVERY</label>
                <input id="daysToDelivery" type="number" pInputText formControlName="daysToDelivery">
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="customer" class="required-mark" translate>OFFER.CUSTOMER</label>
                <p-autoComplete formControlName="buyer"
                                [suggestions]="filtered"
                                (completeMethod)="filterCustomers($event)"
                                [dropdown]="true"
                                (onSelect)="selectBuyer($event)"
                                forceSelection="true"
                                appendTo="body"
                                id="customer"
                                field="name">
                </p-autoComplete>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="project" translate>OFFER.PROJECT</label>
                <p-autoComplete formControlName="project"
                                [suggestions]="filtered"
                                (completeMethod)="filterProjects($event)"
                                [dropdown]="true"
                                forceSelection="true"
                                appendTo="body"
                                id="project"
                                field="name">
                </p-autoComplete>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="receiver" translate>OFFER.RECEIVER</label>
                <p-autoComplete formControlName="receiver"
                                [suggestions]="filtered"
                                (completeMethod)="filterCustomers($event)"
                                [dropdown]="true"
                                forceSelection="true"
                                appendTo="body"
                                id="receiver"
                                field="name">
                </p-autoComplete>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="parities" translate>OFFER.PARITY</label>
                <p-autoComplete formControlName="parity"
                                [suggestions]="filtered"
                                (completeMethod)="filterCodelist($event, 'codelists/parities')"
                                [dropdown]="true"
                                forceSelection="true"
                                appendTo="body"
                                id="parities"
                                field="name">
                </p-autoComplete>
              </div>
              <div class="field col-12 md:col-3" style="padding: 0.5rem; margin-bottom: 0;">
                <label for="remarks" translate>OFFER.NOTES</label>
                <textarea id="remarks" type="text" pInputText formControlName="remarks"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 md:col-3">
          <div class="card p-fluid">
            <h5 style="padding-bottom: 10px;" translate>CUSTOMER.CUSTOMER</h5>
            <div *ngIf="form.get('buyer').value as customer">
              <div class="grid">
                <div class="col-12 md:col-6 p-1 m-0"><span class="font-semibold" translate>CUSTOMERS.NAME</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span>{{ customer.name | empty }}</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span class="font-semibold" translate>CUSTOMERS.VAT</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span>{{ customer.vat | empty }}</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span class="font-semibold" translate>CUSTOMER.ADDRESS</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span>{{ customer.address?.street1 | empty }}
                  , {{ customer.address?.city | empty }} </span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span class="font-semibold" translate>CUSTOMERS.COUNTRY</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span>{{ customer.address?.country.code2 | empty }}</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span class="font-semibold" translate>CUSTOMERS.EMAIL</span></div>
                <div class="col-12 md:col-6 p-1 m-0"><span>{{ customer.email | empty }}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Calculations  -->
      <div class="grid sticky-div-top">
        <div class="col-12">
          <div class="card">
            <div id="invoice-content">
              <div class="invoice">
                <p-table [value]="form.controls.offersArticles.value" dataKey="id" class="narrow-table"
                         [resizableColumns]="true" [autoLayout]="true" [expandedRowKeys]="expandedRows">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="width: 3rem"></th>
                      <th style="width: 40%" translate>OFFER.ARTICLE_NAME</th>
                      <th translate>OFFER.QUANTITY<span class="required-mark"></span></th>
                      <th translate>OFFER.ARTICLE_PRICE<span class="required-mark"> €</span></th>
                      <th translate>OFFER.ARTICLE_DISCOUNT<span> %</span></th>
                      <th translate>OFFER.ARTICLE_VAT<span> %</span></th>
                      <th translate>OFFER.DISCOUNTED_ARTICLE_PRICE</th>
                      <th style="width:3rem"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-offerArticle let-ri="rowIndex">
                    <tr>
                      <td></td>
                      <td><span translate>OFFER.ARTICLE_PRICE_PER_UNIT<span> €</span></span></td>
                      <td [attr.colspan]="1"></td>
                      <td>
                        <div class="mt-1 mb-1">
                          <div class="field">
                            <input [disabled]="offerArticle.article.volume == null" pInputText type="number"
                                   [(ngModel)]="offerArticle.pricePerUnit"
                                   [ngModelOptions]="{ updateOn: 'blur', standalone: true}"
                                   (ngModelChange)="changePricePerUnit(offerArticle, ri)"/>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-offerArticle let-ri="rowIndex" let-expanded="expanded">
                    <tr>
                      <td>
                        <button type="button"
                                pButton
                                [pRowToggler]="offerArticle"
                                (click)="onToggleRow(ri)"
                                class="p-button-text p-button-rounded p-button-plain p-button-sm"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'">
                        </button>
                      </td>
                      <td>{{ offerArticle.article.nameLong }}</td>
                      <td>
                        <input pInputText type="number" [(ngModel)]="offerArticle.quantity"
                               [ngModelOptions]="{ updateOn: 'blur', standalone: true}"
                               (ngModelChange)="onRowEditSave(offerArticle, ri)"/>
                        <span *ngIf="offerArticle.article.packing && offerArticle.article.packing.name === 'Bulk'"
                              class="pl-1">kg</span>
                        <span *ngIf="!offerArticle.article.packing" class="pl-1">kos</span>
                      </td>
                      <td>
                        <input pInputText type="number" [(ngModel)]="offerArticle.priceNet"
                               [ngModelOptions]="{ updateOn: 'blur', standalone: true}"
                               (ngModelChange)="onRowEditSave(offerArticle, ri)"/>
                      </td>
                      <td>
                        <input pInputText type="number" [(ngModel)]="offerArticle.discount"
                               [ngModelOptions]="{ updateOn: 'blur', standalone: true}"
                               (ngModelChange)="onRowEditSave(offerArticle, ri)"/>
                      </td>
                      <td>{{ offerArticle.vat1 }} %</td>
                      <td>{{ offerArticle.priceTotal }} €</td>
                      <td>
                        <button pButton type="button" icon="pi pi-trash"
                                (click)="onRowDelete(offerArticle, ri)"
                                class="p-button-danger table-button"></button>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  Articles Information  -->
      <div class="grid">
        <div class="col-12">
          <div class="card p-fluid">
              <label for="articlesTable" translate>OFFER.SELECT_ARTICLES</label>
              <p-table #dt
                       id="articlesTable"
                       [value]="items$|async"
                       [lazy]="true"
                       (onLazyLoad)="pageChanged($event)"
                       dataKey="articleId"
                       [rowHover]="true"
                       [rows]="filter.limit"
                       [totalRecords]="(count$ | async) || 0"
                       [rowsPerPageOptions]="[5,10,15,20,25]"
                       [showCurrentPageReport]="true"
                       [loading]="loading"
                       [paginator]="true"
                       currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
                       [filterDelay]="0"
                       [globalFilterFields]="['name']"
                       class="narrow-table">
                <ng-template pTemplate="caption">
                  <div
                    class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
                <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                               placeholder="{{'TABLE.GLOBAL_SEARCH' | translate}}" class="p-w-100"/>
                    </span>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="name">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        ARTICLES.NAME
                        <p-sortIcon field="nameLong"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="pantheonId">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        ARTICLE.IDENT
                        <p-sortIcon field="pantheonId"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="volume">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        ARTICLE.VOLUME
                        <p-sortIcon field="volume"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="mass">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        ARTICLE.MASS
                        <p-sortIcon field="mass"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="gloss">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        PRODUCTS.GLOSS
                        <p-sortIcon field="gloss"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="colorRal">
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        PRODUCTS.COLOR_RAL
                        <p-sortIcon field="colorRal"></p-sortIcon>
                      </div>
                    </th>
                    <th>
                      <div class="p-d-flex p-jc-between p-ai-center" translate>
                        OFFER.ARTICLE_PRICE
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-article>
                  <tr class="p-row cursor-pointer" (click)="addArticleOnOffer(article)">
                    <td>
                      {{ article.nameLong }}
                    </td>
                    <td>
                      {{ article.pantheonId }}
                    </td>
                    <td>
                      {{ article.volume }} <span *ngIf="article.volume != null">{{ article.volumeUnit?.name }}</span>
                    </td>
                    <td>
                      {{ article.mass }} <span *ngIf="article.mass != null">{{ article.massUnit?.name }}</span>
                    </td>
                    <td>
                      {{ article.product.gloss?.name }}
                    </td>
                    <td>
                      {{ article.product.colorRal?.code }}
                    </td>
                    <td>
                      {{ article.priceList?.price | currency: 'EUR':'symbol' | empty }}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="8" translate>ARTICLES.NO_ARTICLES_FOUND</td>
                  </tr>
                </ng-template>
              </p-table>
          </div>
        </div>
      </div>
      <!--  Overview  -->
      <div class="grid">
        <div class="col-12">
          <div class="card p-fluid">
            <div class="grid text-right">
              <div class="field col-12 md:col-1">
                <label for="name" class="required-mark" translate>OFFER.DISCOUNT<span> %</span></label>
                <input id="name" type="number" pInputText formControlName="discount">
              </div>

              <div class="invoice-details field col-12 md:col-2">
                <h6 class="invoice-label" translate>OFFER.SUBTOTAL</h6>
                <h6
                  class="invoice-value">{{ pricesService.offerPriceWithoutDiscount(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h6>
              </div>

              <div class="invoice-details field col-12 md:col-2">
                <h6 class="invoice-label" translate>OFFER.ARTICLES_DISCOUNT</h6>
                <h6
                  class="invoice-value">{{ pricesService.articlesPriceDiscount(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h6>
              </div>

              <div class="invoice-details field col-12 md:col-2">
                <h6 class="invoice-label" translate>OFFER.OFFER_DISCOUNT</h6>
                <h6
                  class="invoice-value">{{ pricesService.offerPriceDiscount(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h6>
              </div>

              <div class="invoice-details field col-12 md:col-2">
                <h6 class="invoice-label" translate>OFFER.DISCOUNTED_PRICE</h6>
                <h6
                  class="invoice-value">{{ pricesService.offerPriceDiscountedPrice(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h6>
              </div>

              <div class="invoice-details field col-12 md:col-1">
                <h6 class="invoice-label" translate>OFFER.VAT</h6>
                <h6
                  class="invoice-value">{{ pricesService.offerPriceVat(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h6>
              </div>

              <div class="invoice-details field col-12 md:col-2 text-right">
                <h5 class="invoice-label" translate>OFFER.TOTAL</h5>
                <h5
                  class="invoice-value">{{ pricesService.offerPriceFull(form.getRawValue())|currency: 'EUR':'symbol'|empty }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

<p-footer>
  <div class="card flex justify-content-end pb-2 pt-2 sticky-div-bottom">
    <p-button icon="pi pi-check" class="p-button-raised" styleClass="p-button-success" (onClick)="save()"
              [disabled]="form.invalid" [label]="'BUTTON.SAVE' | translate"></p-button>
  </div>
</p-footer>
