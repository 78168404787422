import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomersService} from '../../../service/customers.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CodelistsService} from '../../../service/codelists.service';
import {PriceList} from '../../../models/offer.model';
import {PricesService} from '../../../service/prices.service';
import {PriceListsService} from '../../../service/pricelists.service';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-pricelist-full-modal',
  templateUrl: './pricelist-full-modal.component.html',
  styleUrls: ['./pricelist-full-modal.component.scss']
})
export class PricelistFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  filteredCustomer: any;
  @Input() priceList: PriceList;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public ref: DynamicDialogRef;

  constructor(
    private customersService: CustomersService,
    private pricelistService: PriceListsService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      id: fb.control(null),
      title: fb.control(null, Validators.required),
      pricelistType: fb.control(null),
      customerType: fb.control(null),
      customerId: fb.control(null),
      description: fb.control(null),
      startDate: fb.control(new Date(), Validators.required),
      endDate: fb.control(null),
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }


  filterCustomers(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.customersService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const pricelist = {...this.priceList, ...this.form.getRawValue()};

    this.pricelistService.create(pricelist)
      .subscribe(
        response => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Successfully created pricelist: ' + pricelist.name,
          });
          this.ref.close(pricelist);
        },
        error => {
          console.log(error);
        });
  }

}
