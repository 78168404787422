import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KnobModule } from 'primeng/knob';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ScrollTopModule } from 'primeng/scrolltop';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppCodeModule } from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { AppInvoiceComponent } from './pages/app.invoice.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';

import { BreadcrumbService } from './app.breadcrumb.service';
import { MenuService } from './app.menu.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OffersComponent } from './offers/offers.component';
import { OfferComponent } from './offers/offer/offer.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { CustomersService } from './service/customers.service';
import { OffersService } from './service/offers.service';
import { DeliveryNotesService } from './service/deliveryNotes.service';
import { ProductsService } from './service/products.service';
import { CodelistsService } from './service/codelists.service';
import { ArticlesService } from './service/articles.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InetisService } from './service/inetis.service';
import { initializer } from '../utils/app-init';
import { HomeComponent } from './layout/home/home.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './products/product/product.component';
import { SharedModule } from './shared/shared.module';
import { OfferViewComponent } from './offers/offer-view/offer-view.component';
import { CustomerFullModalComponent } from './core/modals/customer-full-modal/customer-full-modal.component';
import { ContactFullModalComponent } from './core/modals/contact-full-modal/contact-full-modal.component';
import { ContactsService } from './service/contacts.service';
import { OfferFullModalComponent } from './core/modals/offer-full-modal/offer-full-modal.component';
import { ProductFullModalComponent } from './core/modals/product-full-modal/product-full-modal.component';
import { ArticlesComponent } from './articles/articles.component';
import { ArticleComponent } from './articles/article/article.component';
import { ArticleFullModalComponent } from './core/modals/article-full-modal/article-full-modal.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import {
  BussinesUnitFullModalComponent
} from './core/modals/bussines-unit-full-modal/bussines-unit-full-modal.component';
import { CodelistColorCCComponent } from './codelist-color-cc/codelist-color-cc.component';
import { CodelistColorccFullModalComponent } from './core/modals/codelist-colorcc-full-modal/codelist-colorcc-full-modal.component';
import { ProjectsService } from './service/projects.service';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectFullModalComponent } from './core/modals/project-full-modal/project-full-modal.component';
import { PricesService } from './service/prices.service';
import { ProjectComponent } from './projects/project/project.component';
import { SensorsComponent } from './sensors/sensors/sensors.component';
import { SensorComponent } from './sensors/sensor/sensor.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './orders/order/order.component';
import { OrderViewComponent } from './orders/order-view/order-view.component';
import { OrdersService } from './service/orders.service';
import { OrderFullModalComponent } from './core/modals/order-full-modal/order-full-modal.component';
import { SensorsService } from './service/sensors.service';
import { AuthInterceptor } from '../utils/auth.interceptor';

import localeSl from '@angular/common/locales/sl';
import localeSlExtra from '@angular/common/locales/extra/sl';
import { FloorPlanComponent } from './floor-plan/floor-plan.component';
import { FloorPlanService } from './service/floor-plan.service';
import { ChecklistFullModalComponent } from './core/modals/checklist-full-modal/checklist-full-modal.component';
import {FormsService} from './service/forms.service';
import {NotesService} from './service/notes.service';
import { NoteFullModalComponent } from './core/modals/note-full-modal/note-full-modal.component';
import { NotesComponent } from './notes/notes.component';
import { OpportunitiesComponent } from './opportunities/opportunities/opportunities.component';
import {OpportunitiesService} from './service/opportunities.service';
import {EditorModule} from 'primeng/editor';
import { OpportunityFullModalComponent } from './core/modals/opportunity-full-modal/opportunity-full-modal.component';
import { PriceListsComponent } from './price-lists/price-lists.component';
import {AttachmentFullModalComponent} from './core/modals/attachment-full-modal/attachment-full-modal.component';
import {
  DeliveryNoteFullModalComponent
} from './core/modals/delivery-note-full-modal/delivery-note-full-modal.component';
import {DeliveryNoteComponent} from './delivery-notes/delivery-note/delivery-note.component';
import {DeliveryNotesComponent} from './delivery-notes/delivery-notes.component';
import {DeliveryNoteViewComponent} from './delivery-notes/delivery-note-view/delivery-note-view.component';
import {TableModule} from 'primeng/table';
import {TreeTableModule} from 'primeng/treetable';
import {DxMapModule, DxVectorMapModule} from 'devextreme-angular';
import {StyleClassModule} from 'primeng/styleclass';
import {PricelistFullModalComponent} from './core/modals/pricelist-full-modal/pricelist-full-modal.component';
import {UploadFileFullModalComponent} from './core/modals/upload-file-full-modal/upload-file-full-modal.component';

export function HttpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${new Date().getTime()}`);
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    AvatarModule,
    AvatarGroupModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    CascadeSelectModule,
    ChartModule,
    CheckboxModule,
    ChipModule,
    ChipsModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DividerModule,
    DynamicDialogModule,
    DropdownModule,
    FieldsetModule,
    FileUploadModule,
    GalleriaModule,
    InplaceModule,
    InputNumberModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    KnobModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    RippleModule,
    ScrollPanelModule,
    ScrollTopModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SlideMenuModule,
    SliderModule,
    SplitButtonModule,
    SplitterModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TagModule,
    TerminalModule,
    TimelineModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    AppCodeModule,
    KeycloakAngularModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient]}
    }),
    SharedModule,
    EditorModule,
    DxMapModule,
    DxVectorMapModule,
    StyleClassModule,
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppTopBarComponent,
    AppSearchComponent,
    AppFooterComponent,
    AppLoginComponent,
    AppInvoiceComponent,
    AppHelpComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    HomeComponent,
    DeliveryNoteComponent,
    DeliveryNotesComponent,
    DeliveryNoteViewComponent,
    OffersComponent,
    OfferComponent,
    CustomersComponent,
    CustomerComponent,
    ProductsComponent,
    ProductComponent,
    ArticlesComponent,
    ArticleComponent,
    OfferViewComponent,
    CustomerFullModalComponent,
    BussinesUnitFullModalComponent,
    DeliveryNoteFullModalComponent,
    OfferFullModalComponent,
    ProductFullModalComponent,
    ArticleFullModalComponent,
    ContactFullModalComponent,
    CodelistColorCCComponent,
    CodelistColorccFullModalComponent,
    ProjectsComponent,
    ProjectFullModalComponent,
    AttachmentFullModalComponent,
    ProjectComponent,
    SensorsComponent,
    SensorComponent,
    OrdersComponent,
    OrderComponent,
    OrderViewComponent,
    OrderFullModalComponent,
    FloorPlanComponent,
    ChecklistFullModalComponent,
    NoteFullModalComponent,
    NotesComponent,
    OpportunitiesComponent,
    OpportunityFullModalComponent,
    PriceListsComponent,
    PricelistFullModalComponent,
    UploadFileFullModalComponent
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    MenuService,
    BreadcrumbService,
    CustomersService,
    ContactsService,
    ProductsService,
    ProjectsService,
    SensorsService,
    ArticlesService,
    CodelistsService,
    DeliveryNotesService,
    OffersService,
    OrdersService,
    PricesService,
    InetisService,
    MessageService,
    ConfirmationService,
    DialogService,
    KeycloakService,
    FloorPlanService,
    FormsService,
    NotesService,
    OpportunitiesService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'sl-SI'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeSl, 'sl-SI', localeSlExtra);
  }
}
