import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../service/auth.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PriceListsFilter, PriceListsService} from '../service/pricelists.service';
import {PriceList} from '../models/pricelist.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PricelistFullModalComponent} from '../core/modals/pricelist-full-modal/pricelist-full-modal.component';
import {UploadFileFullModalComponent} from '../core/modals/upload-file-full-modal/upload-file-full-modal.component';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-price-lists',
  templateUrl: './price-lists.component.html',
  styleUrls: ['./price-lists.component.scss']
})
export class PriceListsComponent implements OnInit {

  private items: BehaviorSubject<PriceList[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<PriceList[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));
  filter: PriceListsFilter = new PriceListsFilter(0, 10, 'id');

  loading = false;
  id: string;
  label: string;

  pricelists: PriceList[];

  ref: DynamicDialogRef;

  selectedFile: File | null = null;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public dialogService: DialogService,
    private priceListsService: PriceListsService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  fetch(): void {
    this.loading = true;
    this.priceListsService.find(this.filter).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
      this.pricelists = data.values;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }
  openModal(input: PriceList): void {
    this.ref = this.dialogService.open(PricelistFullModalComponent, {
      header: this.translate.instant('PRICELISTS.PRICELIST'),
      maximizable: true,
      styleClass: 'grey-background-modal',
      width: '50vw',
      height: '50vh',
      data: {
        priceList: input
      }
    });

    this.ref.onClose.subscribe((priceList: PriceList) => {
      if (priceList) {
        this.fetch();
      }
    });
  }

  openUploadModal(priceListId: number): void {
    const ref = this.dialogService.open(UploadFileFullModalComponent, {
      header: this.translate.instant('ATTACHMENTS.UPLOAD_PRICE_LIST'),
      data: {
        id: priceListId,
      },
      width: '50vw',
      height: '20vh',
    });

    ref.onClose.subscribe((result) => {
      if (result) {
        console.log('File upload successful', result);
      }
    });
  }

  viewPriceListData(id): void {
    this.router.navigate(['pricelists/' + id + '/data']);
  }

  delete(priceList: PriceList): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete price list ' + priceList.title + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.priceListsService.delete(priceList.id)
          .subscribe(
            response => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Successfully deleted price list: ' + priceList.title,
              });
              this.fetch();
            },
            error => {
              console.log(error);
            });
      }
    });
  }

  downloadPriceList(priceListId: number): void {
    this.priceListsService.download(priceListId).subscribe({
      next: (file) => {
        // Create a Blob and save the file using file-saver
        const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
        saveAs(blob, `PriceList_${priceListId}.xlsx`);
      },
      error: (err) => {
        console.error('Error downloading the file', err);
      }
    });
  }

}
