import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {AuthService} from '../service/auth.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PriceListsFilter, PriceListsService} from '../service/pricelists.service';
import {PriceList} from '../models/pricelist.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  DeliveryNoteFullModalComponent
} from '../core/modals/delivery-note-full-modal/delivery-note-full-modal.component';
import {PricelistFullModalComponent} from '../core/modals/pricelist-full-modal/pricelist-full-modal.component';

@Component({
  selector: 'app-price-lists',
  templateUrl: './price-lists.component.html',
  styleUrls: ['./price-lists.component.scss']
})
export class PriceListsComponent implements OnInit {

  private items: BehaviorSubject<PriceList[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<PriceList[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));
  filter: PriceListsFilter = new PriceListsFilter(0, 10, 'id');

  loading = false;
  id: string;
  label: string;

  pricelists: PriceList[];

  ref: DynamicDialogRef;

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public dialogService: DialogService,
    private priceListsService: PriceListsService,
    public auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.fetch();
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.close();
    }
  }

  fetch(): void {
    this.loading = true;
    this.priceListsService.find(this.filter).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
      this.pricelists = data.values;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }
  openModal(input: PriceList): void {
    this.ref = this.dialogService.open(PricelistFullModalComponent, {
      header: this.translate.instant('PRICELISTS.PRICELIST'),
      maximizable: true,
      styleClass: 'grey-background-modal',
      width: '50vw',
      height: '50vh',
      data: {
        priceList: input
      }
    });

    this.ref.onClose.subscribe((priceList: PriceList) => {
      if (priceList) {
        this.fetch();
      }
    });
  }
}
