import {Component, OnInit} from '@angular/core';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {LazyLoadEvent, SharedModule} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {TranslateModule} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DialogService} from 'primeng/dynamicdialog';
import {AuthService} from '../../../service/auth.service';
import {PriceListData} from '../../../models/pricelist.model';
import {PriceListDataFilter, PriceListDataService} from '../../../service/pricelistData.service';
import {ActivatedRoute} from '@angular/router';
import {InputTextModule} from 'primeng/inputtext';

@Component({
  selector: 'app-data',
  standalone: true,
  imports: [
    ConfirmDialogModule,
    SharedModule,
    TableModule,
    TranslateModule,
    InputTextModule
  ],
  templateUrl: './data.component.html',
  styleUrl: './data.component.scss'
})
export class DataComponent implements OnInit {

  private items: BehaviorSubject<PriceListData[]> = new BehaviorSubject([]);
  private count: BehaviorSubject<number> = new BehaviorSubject(0);
  private pending: BehaviorSubject<boolean> = new BehaviorSubject(false);

  items$: Observable<PriceListData[]> = this.items.asObservable();
  count$: Observable<number> = this.count.asObservable();
  pending$: Observable<boolean> = this.pending.asObservable();
  empty$: Observable<boolean> = this.count$.pipe(map(count => count === 0));
  filter: PriceListDataFilter = new PriceListDataFilter(0, 10, 'id');

  loading = false;
  id: string;
  label: string;

  priceListData: PriceListData[];

  constructor(
    private priceListDataService: PriceListDataService,
    public dialogService: DialogService,
    public auth: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.fetch();
  }

  fetch(): void {
    this.loading = true;
    this.priceListDataService.find(this.id, this.filter).subscribe(data => {
      this.pending.next(false);
      this.items.next(data.values);
      this.count.next(data.count);
      this.priceListData = data.values;
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }

  pageChanged(event: LazyLoadEvent): void {
    this.filter.limit = event.rows;
    this.filter.offset = event.first;
    this.filter.search = event.globalFilter;
    // console.log(event.sortField);
    // TODO: Check for event.sortField if name make nameLong
    if (event.sortOrder === -1) {
      this.filter.orderBy = '-' + event.sortField;
    } else if (event.sortOrder === 1) {
      this.filter.orderBy = event.sortField;
    }
    this.fetch();
  }
}
