<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="title" class="required-mark" translate>PRICELISTS.TITLE</label>
      <input id="title" type="text" pInputText formControlName="title">
    </div>
    <div class="field col-12 md:col-6">
      <label for="description" translate>PRICELISTS.DESCRIPTION</label>
      <input id="description" type="text" pInputText formControlName="description">
    </div>
    <div class="field col-12 md:col-6">
      <label for="startDate" class="required-mark" translate>PRICELISTS.START_DATE</label>
      <p-calendar id="startDate"
                  formControlName="startDate"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-6">
      <label for="endDate" translate>PRICELISTS.END_DATE</label>
      <p-calendar id="endDate"
                  formControlName="endDate"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-6">
      <label for="priceListType" class="required-mark" translate>PRICELISTS.PRICELIST_TYPE</label>
      <p-autoComplete
        formControlName="priceListType"
        [suggestions]="filtered"
        (completeMethod)="filterCodelist($event, 'codelists/priceListTypes')"
        [dropdown]="true"
        forceSelection="true"
        appendTo="body"
        id="priceListType"
        optionLabel="nameSl">
      </p-autoComplete>

    </div>
    <div *ngIf="form.get('priceListType')?.value?.id === 2"
         class="field col-12 md:col-6">
      <label for="customerId" class="required-mark" translate>OFFER.CUSTOMER</label>
      <p-autoComplete formControlName="customerId"
                      [suggestions]="filtered"
                      (completeMethod)="filterCustomers($event)"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="customerId"
                      field="name">
      </p-autoComplete>
    </div>
    <div *ngIf="form.get('priceListType')?.value?.id === 3"
         class="field col-12 md:col-6">
      <label for="customerTypeId" class="required-mark" translate>CUSTOMERS.CUSTOMER_TYPE</label>
      <p-autoComplete formControlName="customerTypeId"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/customerTypes')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="customerTypeId"
                      field="name">
      </p-autoComplete>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" (click)="save()" [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
