import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';
import {PriceList, PriceListData} from '../models/pricelist.model';

export class PriceListDataFilter extends StandardFilter {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PriceListDataService extends BaseApi  {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(id: any, filter: PriceListDataFilter): Observable<ListData<PriceListData>> {
    return this.list<PriceListData>(`${environment.apiUrl}/priceListsData/${id}`, { params: this.params(filter) });
  }

  // findById(id: any): Observable<PriceListData> {
  //   return this.http.get<PriceListData>(`${environment.apiUrl}/pricelistsData/${id}`);
  // }
  //
  // create(data: any): Observable<any> {
  //   return this.http.post(`${environment.apiUrl}/pricelistsData`, data);
  // }
  //
  // update(id: any, data: any): Observable<any> {
  //   return this.http.put(`${environment.apiUrl}/pricelistsData/${id}`, data);
  // }
  //
  // delete(id: any): Observable<any> {
  //   return this.http.delete(`${environment.apiUrl}/pricelistsData/${id}`);
  // }
}
