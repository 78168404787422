<div class="grid">
  <div class="col-12">
    <div class="card">
      <h5 translate>MENU.NOTES</h5>
      <p-table #dt
               [value]="notes"
               dataKey="id"
               [rowHover]="true"
               [lazy]="true"
               (onLazyLoad)="pageChanged($event)"
               [rows]="filter.limit"
               [totalRecords]="(count$ | async) || 0"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50]"
               [loading]="loading"
               [paginator]="true"
               sortField="modifiedDate"
               [sortOrder]="-1"
               currentPageReportTemplate="{{'TABLE.SHOWING' | translate}}"
               [filterDelay]="0"
               [globalFilterFields]="['title', 'createdDate', 'customer.name']">
        <ng-template pTemplate="caption">
          <div class="flex flex-column sm:flex-row sm:align-items-center sm:justify-content-between gap-3 table-header">
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                       placeholder="{{'TABLE.SEARCH_BY_NAME' | translate}}" class="p-w-100"/>
            </span>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem"></th>
            <th pSortableColumn="title">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                CUSTOMER.ADDRESS
                <p-sortIcon field="number"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="customer.name">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.CUSTOMER
                <p-sortIcon field="buyer.name"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="modifiedBy">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.MODIFIED_BY
                <p-sortIcon field="modifiedBy"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="modifiedDate">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.DATE_MODIFIED
                <p-sortIcon field="modifiedDate"></p-sortIcon>
              </div>
            </th>
            <th pSortableColumn="createdDate">
              <div class="p-d-flex p-jc-between p-ai-center" translate>
                OFFERS.DATE_CREATED
                <p-sortIcon field="createdDate"></p-sortIcon>
              </div>
            </th>
            <th style="width: 6.5rem"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-note let-expanded="expanded">
          <tr class="p-row">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="note"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>
              {{note.title}}
            </td>
            <td>
              {{note.customer.name}}
            </td>
            <td>
              {{note.modifiedBy}}
            </td>
            <td>
              {{note.modifiedDate | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              {{note.createdDate | date: 'dd.MM.yyyy'}}
            </td>
            <td>
              <button pButton type="button" class="p-button-secondary table-button mr-2" icon="pi pi-pencil"
                      (click)="openNoteModal(note)"></button>
              <button pButton type="button" class="p-button-danger table-button" icon="pi pi-trash"
                      (click)="deleteNote(note)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-note>
          <tr>
            <td colspan="5" class="pl-3">
              <span [innerHTML]="note.text"></span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="8" translate>NOTES.NO_NOTES_FOUND</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
