import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BaseApi, ListData, StandardFilter} from '../core/api/base-api';
import {PriceList} from '../models/pricelist.model';

export class PriceListsFilter extends StandardFilter {
  search?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PriceListsService extends BaseApi  {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: PriceListsFilter): Observable<ListData<PriceList>> {
    return this.list<PriceList>(`${environment.apiUrl}/pricelists`, { params: this.params(filter) });
  }

  findById(pricelistId: any): Observable<PriceList> {
    return this.http.get<PriceList>(`${environment.apiUrl}/pricelists/${pricelistId}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pricelists`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pricelists/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/pricelists/${id}`);
  }

  upload(id: any, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file); // Add the file to the form data
    formData.append('id', id.toString()); // Add the id to the form data

    return this.http.post(`${environment.apiUrl}/pricelists/${id}/upload`, formData);
  }

  download(priceListId: number): Observable<Blob> {
    return this.http.get(`${environment.apiUrl}/pricelists/download/${priceListId}`, {
      responseType: 'blob'
    });
  }

}
