<form [formGroup]="form">
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="title" class="required-mark" translate>PRICELISTS.TITLE</label>
      <span class="p-input-icon-left">
            <input id="title" type="text" pInputText formControlName="title">
        </span>
    </div>
    <div class="field col-12 md:col-6">
      <label for="description" class="required-mark" translate>PRICELISTS.DESCRIPTION</label>
      <span class="p-input-icon-left">
            <input id="description" type="text" pInputText formControlName="description">
        </span>
    </div>
    <div class="field col-12 md:col-6">
      <label for="startDate" class="required-mark" translate>PRICELISTS.START_DATE</label>
      <p-calendar id="startDate"
                  formControlName="startDate"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-6">
      <label for="endDate" translate>PRICELISTS.END_DATE</label>
      <p-calendar id="endDate"
                  formControlName="endDate"
                  dateFormat="dd.mm.yy"></p-calendar>
    </div>
    <div class="field col-12 md:col-6">
      <label for="customerType" class="required-mark" translate>PRICELISTS.PRICELIST_TYPE</label>
      <p-autoComplete formControlName="pricelistType"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/pricelistTypes')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="pricelistType"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-6">
      <label for="customerId" class="required-mark" translate>OFFER.CUSTOMER</label>
      <p-autoComplete formControlName="customerId"
                      [suggestions]="filtered"
                      (completeMethod)="filterCustomers($event)"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="customerId"
                      field="name">
      </p-autoComplete>
    </div>
    <div class="field col-12 md:col-6">
      <label for="customerType" class="required-mark" translate>CUSTOMERS.CUSTOMER_TYPE</label>
      <p-autoComplete formControlName="customerType"
                      [suggestions]="filtered"
                      (completeMethod)="filterCodelist($event, 'codelists/customerTypes')"
                      [dropdown]="true"
                      forceSelection="true"
                      appendTo="body"
                      id="customerType"
                      field="name">
      </p-autoComplete>
    </div>
  </div>
</form>
<p-footer>
  <p-button icon="pi pi-check" class="p-button-raised" (click)="save()" [disabled]="form.invalid"
            [label]="'BUTTON.SAVE' | translate"></p-button>
</p-footer>
