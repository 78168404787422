import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {OffersComponent} from './offers/offers.component';
import {OfferComponent} from './offers/offer/offer.component';
import {CustomersComponent} from './customers/customers.component';
import {CustomerComponent} from './customers/customer/customer.component';
import {HomeComponent} from './layout/home/home.component';
import {ProductsComponent} from './products/products.component';
import {ProductComponent} from './products/product/product.component';
import {OfferViewComponent} from './offers/offer-view/offer-view.component';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleComponent} from './articles/article/article.component';
import {CodelistColorCCComponent} from './codelist-color-cc/codelist-color-cc.component';
import {ProjectsComponent} from './projects/projects.component';
import {ProjectComponent} from './projects/project/project.component';
import {SensorsComponent} from './sensors/sensors/sensors.component';
import {SensorComponent} from './sensors/sensor/sensor.component';
import {OrderViewComponent} from './orders/order-view/order-view.component';
import {OrderComponent} from './orders/order/order.component';
import {OrdersComponent} from './orders/orders.component';
import {AuthGuard} from '../utils/app.guard';
import {NotesComponent} from './notes/notes.component';
import {OpportunitiesComponent} from './opportunities/opportunities/opportunities.component';
import {PriceListsComponent} from './price-lists/price-lists.component';
import {DeliveryNotesComponent} from './delivery-notes/delivery-notes.component';
import {DeliveryNoteComponent} from './delivery-notes/delivery-note/delivery-note.component';
import {PriceListComponent} from './price-lists/price-list/price-list.component';
import {ImportsComponent} from './imports/imports.component';
import {ImportComponent} from './imports/import/import.component';
import {DataComponent} from './price-lists/price-list/data/data.component';

const routes: Routes = [
  {
    path: '', component: AppMainComponent, canActivate: [AuthGuard],
    children: [
      {path: '', component: HomeComponent},
      {path: 'offers', component: OffersComponent},
      {path: 'offer/:id', component: OfferComponent},
      {path: 'offer/add', component: OfferComponent},
      {path: 'offerView/:id', component: OfferViewComponent},
      {path: 'deliveryNotes', component: DeliveryNotesComponent},
      {path: 'deliveryNote/:id', component: DeliveryNoteComponent},
      {path: 'deliveryNote/add', component: DeliveryNoteComponent},
      {path: 'deliveryNoteView/:id', component: DeliveryNoteComponent},
      {path: 'orders', component: OrdersComponent},
      {path: 'order/:id', component: OrderComponent},
      {path: 'order/add', component: OrderComponent},
      {path: 'orderView/:id', component: OrderViewComponent},
      {path: 'customers', component: CustomersComponent},
      {path: 'customer/:id', component: CustomerComponent},
      {path: 'customer/add', component: CustomerComponent},
      {path: 'products', component: ProductsComponent},
      {path: 'product/add', component: ProductComponent},
      {path: 'product/:id', component: ProductComponent},
      {path: 'projects', component: ProjectsComponent},
      {path: 'project/add', component: ProjectComponent},
      {path: 'project/:id', component: ProjectComponent},
      {path: 'articles', component: ArticlesComponent},
      {path: 'article/:id', component: ArticleComponent},
      {path: 'article/add', component: ArticleComponent},
      {path: 'colorcc', component: CodelistColorCCComponent},
      {path: 'sensors', component: SensorsComponent},
      {path: 'sensor/:id', component: SensorComponent},
      {path: 'sensor', component: SensorComponent},
      {path: 'notes', component: NotesComponent},
      {path: 'notes/:id', component: NotesComponent},
      {path: 'notes/add', component: NotesComponent},
      {path: 'opportunities', component: OpportunitiesComponent},
      {path: 'opportunities/:id', component: OpportunitiesComponent},
      {path: 'opportunities/add', component: OpportunitiesComponent},
      {path: 'pricelists', component: PriceListsComponent},
      {path: 'pricelists/:id', component: PriceListComponent},
      {path: 'pricelists/:id/data', component: DataComponent},
      {path: 'imports', component: ImportsComponent},
      {path: 'imports/:id', component: ImportComponent},
    ]
  },
  {path: 'error', component: AppErrorComponent},
  {path: 'access', component: AppAccessdeniedComponent},
  {path: 'notfound', component: AppNotfoundComponent},
  {path: 'login', component: AppLoginComponent},
  {path: '**', redirectTo: '/notfound'},
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
