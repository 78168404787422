<p-fileUpload name="file"
              [customUpload]="true"
              (uploadHandler)="onFileUpload($event)"
              chooseLabel="{{ 'ATTACHMENTS.UPLOAD_PRICE_LIST' | translate }}"
              uploadLabel="{{ 'ACTIONS.UPLOAD' | translate }}"
              cancelLabel="{{ 'ACTIONS.CANCEL' | translate }}"
              multiple="false"
              maxFileSize="10000000"
              accept=".xlsx,.xls"
              class="mb-3">
</p-fileUpload>
