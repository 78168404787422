import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product.model';
import { BaseApi, ListData, StandardFilter } from '../core/api/base-api';

export class ProductsFilter extends StandardFilter {
  search?: string;
  productGroupId?: number;
  name: string;
}

@Injectable()
export class ProductsService extends BaseApi {

  constructor(protected http: HttpClient) {
    super(http);
  }

  find(filter: ProductsFilter): Observable<ListData<Product>> {
    return this.list<Product>(`${environment.apiUrl}/products`, { params: this.params(filter) });
  }

  findById(id: number): Observable<Product> {
    return this.http.get<Product>(`${environment.apiUrl}/products/${id}`);
  }

  create(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/products`, data);
  }

  update(id: any, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/products/${id}`, data);
  }

  delete(id: any): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/products/${id}`);
  }

  findByName(name: any): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiUrl}/products?name=${name}`);
  }

  filterWrapper(filter: ProductsFilter): ProductsFilter {
    const newFilter = filter;
    if (filter != null && filter.search != null) {
      // newFilter.search = encodeURIComponent(filter.search);
    }
    return newFilter;
  }
}
