import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomersService} from '../../../service/customers.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from 'primeng/api';
import {CodelistsService} from '../../../service/codelists.service';
import {PriceListsService} from '../../../service/pricelists.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {PriceList} from '../../../models/pricelist.model';

@Component({
  selector: 'app-pricelist-full-modal',
  templateUrl: './pricelist-full-modal.component.html',
  styleUrls: ['./pricelist-full-modal.component.scss']
})
export class PricelistFullModalComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  filtered: any;
  filteredCustomer: any;
  @Input() priceList: PriceList;
  private saving: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private customersService: CustomersService,
    private pricelistService: PriceListsService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private codelistsService: CodelistsService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      version: fb.control(null),
      title: fb.control(null, Validators.required),
      priceListType: fb.control(null, Validators.required),
      customerTypeId: fb.control(null),
      customerId: fb.control(null),
      description: fb.control(null),
      startDate: fb.control(new Date(), Validators.required),
      endDate: fb.control(null),
    });
  }

  ngOnInit(): void {
    if (this.config && this.config.data && this.config.data.priceList) {
      this.priceList = this.config.data.priceList;
    }
    if (this.priceList && this.priceList.id) {
      this.form.patchValue(this.priceList);
      this.form.get('startDate').setValue(new Date(this.priceList.startDate));
      if (this.priceList.endDate) {
      this.form.get('endDate').setValue(new Date(this.priceList.endDate));
      }
    }
    // Listen to priceListType changes and update validators accordingly
    this.form.get('priceListType')?.valueChanges.subscribe((priceListType) => {
      this.updateValidationForFields(priceListType.id);
    });

  }

  ngOnDestroy(): void {
    this.saving.complete();
  }

  /**
   * Dynamically update validators for customerId and customerType based on priceListType
   */
  private updateValidationForFields(priceListType: number): void {
    const customerIdControl = this.form.get('customerId');
    const customerTypeControl = this.form.get('customerTypeId');

    if (!customerIdControl || !customerTypeControl) {
      return; // Guards against null form controls
    }

    // Reset all validators first
    customerIdControl.clearValidators();
    customerTypeControl.clearValidators();

    if (priceListType === 1) {
      customerIdControl.reset();
      customerTypeControl.reset();
      customerIdControl.setValue(null);
      customerTypeControl.setValue(null);


    } else if (priceListType === 2) {
      // priceListType ID is 2, make "customerId" mandatory
      customerIdControl.setValidators(Validators.required);
      customerTypeControl.reset();
      customerTypeControl.setValue(null);

    } else if (priceListType === 3) {
      // priceListType ID is 3, make "customerType" mandatory
      customerTypeControl.setValidators(Validators.required);
      customerIdControl.reset();
      customerIdControl.setValue(null);
    }

    // Recalculate validation status for the updated fields
    customerIdControl.updateValueAndValidity();
    customerTypeControl.updateValueAndValidity();
  }

  filterCodelist(event, codelist): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.codelistsService.findByName(query, codelist)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  filterCustomers(event): void {
    let filtered: any[] = [];
    const query = event.query;
    // a request to a remote url with the query and return filtered results
    this.customersService.findByName(query)
      .subscribe(
        data => {
          if (data != null) {
            filtered = data;
          }
          this.filtered = filtered;
        },
        error => {
          console.log(error);
        });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    // Change values to match
    this.form.get('priceListType')?.setValue({id: this.form.get('priceListType')?.value.id}); // Set only the priceListType
    if (this.form.get('customerTypeId')?.value) {
      this.form.get('customerTypeId')?.setValue(this.form.get('customerTypeId')?.value.id); // Set only the customerTypeId
    }
    if (this.form.get('customerId')?.value) {
      this.form.get('customerId')?.setValue(this.form.get('customerId')?.value.id); // Set only the customerId
    }


    const pricelist = {...this.priceList, ...this.form.getRawValue()};

    if (pricelist.id) {
      this.pricelistService.update(pricelist.id, pricelist)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully updated price list: ' + pricelist.title,
            });
            this.ref.close(pricelist);
          });
    } else {
      this.pricelistService.create(pricelist)
        .subscribe(
          response => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Successfully created price list: ' + pricelist.title,
            });
            this.ref.close(pricelist);
          });
    }
  }
}
